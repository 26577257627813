<template>        
        <div class="main main-raised" >
            <div class="profile-content">
                <div class="container">
                    <!-- SC Logo -->
                    <div>
                        <a href="https://www.smartcharts.net/" style="z-index: 1; margin: 0 auto;"><img src="images/logo.png" alt="Smartchart Logo" style="margin-top: 12px;"></a>
                    </div>
                    <!-- Loading -->
                    <template v-if="loading">
                        <br> 
                        <div class="row">
                            <div class="col-md-6 ml-auto mr-auto">
                                <div class="spinner-border text-light" role="status" style="color:white !important;">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        <br><br>
                    </template>
                    <!-- Loaded DATA -->
                    <template v-if="!loading">
                        <!-- Enter email -->
                        <template v-if="!params.email">
                            <div class="row" v-if="params.hash">
                                <div class="col-md-6 ml-auto mr-auto">
                                    <br><br>
                                    <b-input-group class="mt-3">
                                        <b-form-input :state="emailError ? false : true" autocomplete="off" placeholder="Enter your registered email" v-model="email"></b-form-input>
                                        <b-input-group-append style="margin-top: -5px !important;">
                                        <b-button variant="success" @click="verifyEmail()">SUBMIT</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-alert show variant="danger" v-if="emailError">{{ emailError }}</b-alert>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-md-6 ml-auto mr-auto text-white">
                                    <br><br>Welcome to Customer's Page! <br>Kindly check your email for the correct link. <br>Thank you.
                                </div>
                            </div>
                        </template>
                        <!-- DATA details -->
                        <template v-else>
                            <div class="row" v-if="customerInstallments.customer">
                                <div class="col-md-6 ml-auto mr-auto">
                                    <div class="profile">
                                        <div class="avatar">
                                            <br><br><br>
                                        </div>
                                        <div class="name">
                                            <h2 class="title all-caps" style="font-family: Avenir, Helvetica, Arial, sans-serif; color: white;">
                                                Installment Payments</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-if="customerInstallments.installment">

                                <!-- customer details -->
                                <b-list-group horizontal="md">
                                    <b-list-group-item class="bg-primary">Name:</b-list-group-item>
                                    <b-list-group-item>{{ customerInstallments.customer.first_name }} {{ customerInstallments.customer.last_name }}</b-list-group-item>
                                </b-list-group>
                                <b-list-group horizontal="md">
                                    <b-list-group-item  class="bg-primary">Email:</b-list-group-item>
                                    <b-list-group-item>{{ customerInstallments.customer.email }}</b-list-group-item>
                                </b-list-group>

                                <!-- :INSTALLMENTS LIST: -->
                                <div  id="accordion" v-for="(installment, index) in customerInstallments.installment" v-bind:key="index">
                                    <template v-if="installment.enable_customer_page" >
                                        <div class="card">

                                            <div class="card-header bg-default" style="padding: 15px;" :id="'installment-'+index" >
                                                <span class="float-left mlink" :class="index=='0' ? '':'collapsed'" data-toggle="collapse" :data-target="'#collapse-'+index" :aria-expanded="index=='0' ? true:false" :aria-controls="'collapse-'+index" title="Toggle">
                                                    <h5><b-icon icon="chevron-double-down" aria-hidden="true"></b-icon></h5>
                                                </span>
                                                <span class="mlink" :class="index=='0' ? '':'collapsed'" data-toggle="collapse" :data-target="'#collapse-'+index" :aria-expanded="index=='0' ? true:false" :aria-controls="'collapse-'+index">
                                                    <h5><b>{{ installment.addon_name }}</b></h5>
                                                </span>
                                            </div>

                                            <div :id="'collapse-'+index" class="collapse" :class="index=='0' ? 'show':''" :aria-labelledby="'installment-'+index" data-parent="#accordion">
                                                <div class="card-body" style="background: #132d50;">
                                                <ul class="list-group list-group-flush" style="text-align:left;     background-color: #132d50 !important;">
                                                    <!-- <li class="list-group-item"><b>Course:</b> {{ installment.addon_name }}</li> -->
                                                    <li class="list-group-item"><b>Status:</b> {{ installment.debt_status }}</li>
                                                    <li class="list-group-item"><b>Event:</b> {{ installment.event_name }}</li>
                                                    <li class="list-group-item"><b>Subscription:</b> {{ installment.plan_name }}</li>
                                                    <li class="list-group-item"><b>Total Course Price:</b> <span>{{ installment.course_override_price != '0.00' ? installment.course_override_price : installment.price | toCurrency }} {{ installment.currency }}</span></li>
                                                    <li class="list-group-item"><b>Total Plan Price:</b> <span>{{ installment.plan_price | toCurrency }} {{ installment.currency }}</span></li>
                                                    <li class="list-group-item" v-if="isOffline"><b>Balance Amount:</b> <span style="color:red;">{{ installment.balance | toCurrency }} {{ installment.currency }}</span></li>
                                                    <li class="list-group-item" v-else><b>Balance Amount:</b> <span style="color:red;">{{ installment.balance + installment.plan_price | toCurrency }} {{ installment.currency }}</span></li>
                                                </ul>

                                                    <div class="row">
                                                        <!-- <div class="col-md-2">
                                                            <b-button variant="outline-primary" @click="toggleView()" :title="grid===true ? 'View data in LIST' : 'View data in GRID'">
                                                                <b-icon :icon="grid===true ? 'list-ul' : 'grid3x3-gap-fill'"></b-icon> {{ grid===true ? 'List' : 'Grid' }}
                                                            </b-button>
                                                            <br><br>
                                                        </div> -->
                                                        <div class="col-md-12">
                                                            <b style="padding-top: 14px; color: white; font-size: 20px;">SmartCharts Payment Schedule</b>
                                                            <br><br>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <template v-if="grid">
                                                            <!-- GRID VIEW -->
                                                            <div class="col-md-6 col-lg-6" v-for="(schedule, key) in installment.installment_payment_schedules"  v-bind:key="key">
                                                                <div v-if="schedule.deleted != 1" class="card mb-3" :class="resolveClass(schedule)">
                                                                    <div class="card-header" style="padding-top: 11px;">
                                                                        <h5 class="mlink all-caps" title="View transactions" @click="showModal(index, key)"><b>{{ schedule.status }}</b></h5>
                                                                    </div>
                                                                    <div class="card-body" v-if="isLastInstallmentSchedule(key, installment.installment_payment_schedules)">
                                                                        <h5 class="card-title" v-if="isOffline">{{ resolveCurrencyFormat(schedule.amount, schedule.currency) }}</h5>
                                                                        <h5 class="card-title" v-else>{{ resolveCurrencyFormat(parseInt(schedule.amount) + parseInt(installment.plan_price), schedule.currency) }}</h5>
                                                                        <p class="card-text">Installment Price: <b>{{ resolveCurrencyFormat(schedule.amount, schedule.currency) }}</b></p>
                                                                        <p class="card-text" v-if="!isOffline">Subscription Price: <b>{{ resolveCurrencyFormat(installment.plan_price, schedule.currency) }} <small>per {{installment.billing_period_unit}}</small></b></p>
                                                                        <p class="card-text">Due date: <b>{{ schedule.due_date | moment("dddd, MMMM DD, YYYY") }}</b></p>
                                                                        <p class="card-text">Payment Method: <b class="all-caps">{{ schedule.payment_method ? schedule.payment_method : 'N/A' }}</b></p>
                                                                        <p class="card-text">Amount paid: <b>{{ resolveCurrencyFormat(schedule.amount_paid, schedule.currency) }}</b></p>
                                                                        <p class="card-text">
                                                                            <b-button variant="primary" size="sm" class="mt-3" block @click="showModal(index, key)">View Transactions</b-button>
                                                                            <b></b>
                                                                        </p>
                                                                    </div>
                                                                    <div class="card-body" v-else>
                                                                        <h5 class="card-title">{{ resolveCurrencyFormat(schedule.amount, schedule.currency) }}</h5>
                                                                        <p class="card-text">Due date: <b>{{ schedule.due_date | moment("dddd, MMMM DD, YYYY") }}</b></p>
                                                                        <p class="card-text">Payment Method: <b class="all-caps">{{ schedule.payment_method ? schedule.payment_method : 'N/A' }}</b></p>
                                                                        <p class="card-text">Amount paid: <b>{{ resolveCurrencyFormat(schedule.amount_paid, schedule.currency) }}</b></p>
                                                                        <p class="card-text">
                                                                            <b-button variant="primary" size="sm" class="mt-3" block @click="showModal(index, key)">View Transactions</b-button>
                                                                            <b></b>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-md-6 col-lg-6">
                                                                <div class="card bg-warning mb-3">
                                                                    <div class="card-header" style="padding-top: 11px;">
                                                                        <h5 class="all-caps" style="margin-bottom: 0;"><b>{{ installment.plan_name }}</b></h5>
                                                                        <small style="color:blue;">Subscription</small>
                                                                    </div>
                                                                    <div class="card-body">
                                                                        <h5 class="card-title">{{ resolveCurrencySymbol(installment.currency) }} {{ ((installment.plan_price*1)/100) | toCurrency }}</h5>
                                                                        <h6>( {{ resolveCycleUnit(installment.billing_period_unit) }} payment )</h6>
                                                                        <p class="card-text">Date: <b>{{
                                                                            fetchLastInstallmentDate(installment.installment_payment_schedules) }}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                        </template>
                                                        <div class="col-md-12" v-else>
                                                            <!-- LIST VIEW -->
                                                            <b-table responsive hover :items="installment.installment_payment_schedules" :fields="fields" :tbody-tr-class="rowClass" >
                                                                <template #cell(status)="data">
                                                                    <p title="View Transactions" class="vlink" @click="showModal(index, data.index)">{{ data.item.status }}</p>
                                                                </template>
                                                                <template #cell(payment_method)="data">
                                                                    <p class="all-caps">{{ data.item.payment_method ? data.item.payment_method : 'N/A' }}</p>
                                                                </template>
                                                                <template #cell(amount)="data">
                                                                    <p class="all-caps pull-right">{{ resolveCurrencyFormat(data.item.amount, data.item.currency) }}</p>
                                                                </template>
                                                                <template #cell(amount_paid)="data">
                                                                    <p class="all-caps pull-right">{{ resolveCurrencyFormat(data.item.amount_paid, data.item.currency) }}</p>
                                                                </template>
                                                                <template #cell(action)="data">
                                                                        <b-button variant="primary" size="sm"  @click="showModal(index, data.index)" title="View Transactions">
                                                                            View
                                                                        </b-button>
                                                                </template>
                                                            </b-table>
                                                        </div>
                                                        <div>
                                                            <b-modal id="modal-trans" size="lg" hide-footer>
                                                                <template #modal-title>
                                                                    <b-badge :variant="customerInstallments.installment[modalIndexes.i].installment_payment_schedules[modalIndexes.ips].status == 'Paid' ? 'success':'danger'" class="all-caps">{{ customerInstallments.installment[modalIndexes.i].installment_payment_schedules[modalIndexes.ips].status }}</b-badge>
                                                                </template>
                                                                <div class="d-block text-center" v-if="customerInstallments">
                                                                    <p style="text-align:left;">Amount Due: <b>{{ customerInstallments.installment[modalIndexes.i].installment_payment_schedules[modalIndexes.ips].amount | toCurrency }} {{ customerInstallments.installment[modalIndexes.i].installment_payment_schedules[modalIndexes.ips].currency }}</b></p>
                                                                    <p style="text-align:left;">Amount Paid: <b>{{ customerInstallments.installment[modalIndexes.i].installment_payment_schedules[modalIndexes.ips].amount_paid | toCurrency }} {{ customerInstallments.installment[modalIndexes.i].installment_payment_schedules[modalIndexes.ips].currency }}</b></p>
                                                                    <p style="text-align:left;">Due date: <b>{{ customerInstallments.installment[modalIndexes.i].installment_payment_schedules[modalIndexes.ips].due_date | moment("dddd, MMMM DD, YYYY") }}</b></p>
                                                                    <hr>
                                                                    <p><b>Transactions</b></p>
                                                                    <template v-if="hasTransactions()">
                                                                        <b-table responsive hover :items="customerInstallments.installment[modalIndexes.i].installment_payment_schedules[modalIndexes.ips].installment_payment_transactions" :fields="modalFields" :tbody-tr-class="rowClass">
                                                                            <template #cell(status)="data">
                                                                                <p class="all-caps">{{ data.item.status }}</p>
                                                                            </template>
                                                                            <template #cell(amount)="data">
                                                                                <p>{{ resolveCurrencyFormat(data.item.amount, customerInstallments.installment[modalIndexes.i].installment_payment_schedules[modalIndexes.ips].currency) }}</p>
                                                                            </template>
                                                                            <template #cell(created_at)="data">
                                                                                <p>{{ data.item.created_at | moment(" MMMM DD, YYYY hh:mm A") }}</p>
                                                                            </template>
                                                                        </b-table>
                                                                    </template>
                                                                    <template v-else>
                                                                        <b-alert show variant="danger">No Data Found.</b-alert>
                                                                    </template>
                                                                </div>
                                                                <b-button variant="default" class="mt-3" block @click="$bvModal.hide('modal-trans')">Close</b-button>
                                                            </b-modal>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </template>

                                    <div v-else class="text-warning-message">
                                        Your payment schedule will shortly be sent to you by email.
                                    </div>
                                </div>

                            </template>
                            <div v-else>
                                <br> 
                                <div class="text-center">
                                    <b-spinner label="Spinning">Loading ...</b-spinner>
                                </div>
                                <br>
                            </div>
                        </template>
                        <br><br>
                    </template>
                </div>
            </div>
        </div>
</template>


<script>
import axios from 'axios';
import moment from 'moment'

export default {
    name: "Customer",
    data() {
        return {
            fields: [
                {
                    key: 'due_date',
                    formatter: "formatDate",
                    sortable: true
                },
                {
                    key: 'amount',
                    // formatter: "formatAmount",
                    label: 'Amount Due',
                    sortable: true
                },
                {
                    key: 'payment_method',
                    sortable: true
                },
                {
                    key: 'amount_paid',
                    // formatter: "formatAmount",
                    label: 'Amount Paid',
                    sortable: true
                },
                {
                    key: 'status',
                    sortable: true,
                    formatter: value => {
                        return value.toUpperCase()
                    }
                },
                {
                    key: 'action',
                    dataClass: 'text-right'
                }
            ],
            modalFields: [
                {
                    key: 'status',
                    sortable: true
                },
                {
                    key: 'amount',
                    formatter: "formatAmount",
                    sortable: true
                },
                {
                    key: 'transaction_id',
                    sortable: true
                },
                {
                    key: 'created_at',
                    label: 'Date Time',
                    formatter: "formatDateTime",
                    sortable: true
                },
            ],
            loading: false,
            params: {
                hash: '',
                email: ''
            },
            email: '',
            customerInstallments: {},
            url: process.env.VUE_APP_API_ENDPOINT,
            emailError: '',
            grid: true,
            modalIndexes: {
                i: 0,
                ips: 0
            },
            modalLoading: true,
            isOffline: false
        }
        // t=%242y%2410%24qNN9AKIhKhZWPPL%2F0PPesun5Bx8eLkdC1mTu8YikDviqBi5FHIgOW&e=remremdummy%2Bf1%40gmail.com
        // $2y$10$qNN9AKIhKhZWPPL/0PPesun5Bx8eLkdC1mTu8YikDviqBi5FHIgOW
        // remremdummy+f1@gmail.com
    },
    created()
     {
        this.loading = true;
        let urlParams = new URLSearchParams(window.location.search);
        this.params.hash = urlParams.get('t');

        this.loading = false;
        // if (window.location.href.indexOf("customer-page") > -1) {
            if(this.params.email){
                this.fetchCustomerDetails();
            }
        // }
     },
     methods:
     {
        fetchCustomerDetails(){
            this.loading = true;
            axios.post(this.url, 
                { email: this.params.email },
                { headers: {'x-api-key': this.params.hash, Authorization: "x-api-key", crossdomain: true, 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'Content-Type, Authorization'} }
            )
            .then( response => {
                if(Object.prototype.hasOwnProperty.call(response.data, 'message')) {
                    this.params.email = '';
                    this.emailError = response.data.message;
                }else{
                    this.customerInstallments = response.data;
                    this.isOfflinePayment(this.customerInstallments.installment[0].installment_payment_schedules[0])
                }
                this.loading = false;
            })
            .catch((error) => {
                console.log("error",error);
                this.emailError = 'No Data Found.';
                this.customerInstallments = {};
                this.params.email = '';
                this.loading = false;
            });
            this.loading = false;
        },
        fetchLastInstallmentDate(installmentSchedules){
            // this.loading = true;
            // let installments = this.customerInstallments;
                let installmentsCount = Object.keys(installmentSchedules).length;
                if(installmentsCount>0){
                    let lastInstallment = installmentSchedules[installmentsCount-1];
                    return moment(lastInstallment.due_date).format("dddd, MMMM DD, YYYY");
                }
                return;
            // this.loading = false;
        },
        isLastInstallmentSchedule(key, installmentSchedules){
            console.log(key)
            let installmentsCount = Object.keys(installmentSchedules).length;
            if(installmentsCount>0){
                key = key*1;
                let installmentLastKey = installmentsCount-1;
                if(key === installmentLastKey)
                    return true;
            }
            return false;
        },
        isOfflinePayment(installmentSchedule) {
            if (installmentSchedule.payment_method == 'other') {
                this.isOffline = true
            }
        },
        resolveCurrencySymbol(currency){
            if(currency=='PHP'){
                return '₱';
            }
            return '$';
        },
        resolveCurrencyFormat(amount, currencyCode) {
            let currencyFormat = Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: currencyCode
            })

            return currencyFormat.format(amount)
        },
        resolveClass(data){   
            if(data.status=='Paid'){
                return 'bg-success';
            }
            if(data.status=='Pending'){
                if(moment(data.due_date)<moment())
                return 'bg-danger';
            }
            return 'bg-light';
        },
        resolveHeaderClass(index){
            index = Number(index);
            if(index%2==0){
                return 'bg-primary';
            }
            return 'bg-primary';
        },
        resolveCycleUnit(unit){
            switch(unit){
                case 'day':
                    return 'Daily';
                case 'week':
                    return 'Weekly';
                case 'month':
                    return 'Monthly';
                case 'year':
                    return 'Yearly';
                default:
                    return '';
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'Paid' || item.status === 'success') 
                return 'table-success'
            if(item.status === 'Pending'){
                if(moment(item.due_date)<moment()){
                    return 'table-danger';
                }
            }
            if (item.status === 'failed')
                return 'table-danger'
        },
        formatDate(value) {
            return moment(value, 'YYYY-MM-DD').format('dddd, MMMM DD, YYYY');
        },
        formatDateTime(value) {
            return moment(value, 'YYYY-MM-DD').format('dddd, MMMM DD, YYYY H:i');
        },
        formatAmount(value){
            if(value==''||value==null||value==undefined){
                value = 0;
            }
            let n = Number(value).toFixed(2);
            return Number(n).toLocaleString();
        },
        toggleView(){
            this.grid = !this.grid;
        },
        verifyEmail(){
            this.params.email = this.email;
            this.fetchCustomerDetails();
        },
        showModal(_i, _ips){
            this.modalLoading = true;
            this.modalIndexes = {
                i: _i,
                ips: _ips
            };
            console.log("modalIndex", this.modalIndexes);
            this.modalLoading = false;
            this.$bvModal.show('modal-trans');
        },
        hasTransactions(){
            if(this.hasData(this.customerInstallments, 'installment')) {
                // console.log("installment1", this.customerInstallments.installment);

                if(this.hasData(this.customerInstallments.installment[this.modalIndexes.i], 'installment_payment_schedules')){
                    // console.log("installment2", this.customerInstallments.installment[this.modalIndexes.i].installment_payment_schedules);

                    if(this.hasLength(this.customerInstallments.installment[this.modalIndexes.i].installment_payment_schedules)){

                        if(this.hasData(this.customerInstallments.installment[this.modalIndexes.i].installment_payment_schedules[this.modalIndexes.ips], 'installment_payment_transactions')){
                            // console.log("installment3", this.customerInstallments.installment[this.modalIndexes.i].installment_payment_schedules[this.modalIndexes.ips].installment_payment_transactions);
    
                            if(this.hasLength(this.customerInstallments.installment[this.modalIndexes.i].installment_payment_schedules[this.modalIndexes.ips].installment_payment_transactions)){
                                return true;
                            }
                        }
                    }

                }
            }
            return false;
        },
        hasData(data, key){
            return Object.prototype.hasOwnProperty.call(data, key) ? true : false;
        },
        hasLength(data){
            return Object.keys(data).length > 0 ? true: false;
        }
     }
}
</script>

<style scoped>
.bg-warning{
    background-color: #e8eabb !important;
}

.bg-info{
    background-color: #78d6e5 !important;
}

.bg-success{
    background-color: #c3e6cb !important;
    /* background-color: #78e5a4 !important; */
}

.bg-danger{
    background: #f5c6cb !important;
    /* background-color: #f893a1 !important; */
}

.bg-primary{
    color: white;
    background-color: #132d50 !important;
}

.card .card-header{
    padding: 0px;
    background-color: none;
}

.btn-link{
    text-decoration: none !important;
    color: black !important;
}
.btn-link > h5 > b:hover {
  color: rgb(44, 160, 206) !important;
}

.btn{
    text-transform: none !important;
}
.btn-success{
    color: #fff;
    background-color: #198754;
    border-color: #198754;
}
.btn-light{
    color: #000000;
    background-color: #f6f6f6;
    border-color: #d3cfcf;
}

.btn-default{
    color: #fff;
    background-color: #6a7570;
    border-color: #6a7570;
}

.btn-primary{
    background-color: #1f467a;
    color: #fafafc;
}

.btn-primary:hover{
    /* background-color: #fff; */
    border-color: #1f467a;
    color: #1f467a;
}

.btn-outline-primary{
    border-color: #1f467a;
    color: #1f467a;
}
.btn-outline-primary:hover{
    background-color: #1f467a;
    color: #fafafc;
}

.list-group-flush .list-group-item{
    background-color: #132d50 !important;
    color: white;
    font-size: 17px;
}

.list-group-horizontal-md .list-group-item{
    border: 1px solid white !important;
}

.card-body .card-text{
    margin-bottom: 5px;
}

.card{
    border-radius: 0px !important;
}

.text-warning-message {
    color: white;
    margin-top: 40px;
    text-align: center;
}
</style>