import Vue from 'vue'
import VueRouter from 'vue-router'
import Customer from '../components/pages/Customer.vue'
import NotFound from '../components/errors/NotFound.vue'
import Vue2Filters from 'vue2-filters'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { ModalPlugin, TablePlugin, BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(TablePlugin)
Vue.use(Vue2Filters)
Vue.use(require('vue-moment'));
Vue.filter('toCurrency', function (value) {
    if(value==''||value==null||value==undefined){
        value = 0;
    }
    // var n = Number(value).toFixed(2);
    // return Number(n).toLocaleString();
    return Number(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.use(VueRouter)

const routes = [
  {
    path: '/customer-page',
    name: 'Customer',
    component: Customer
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/pages/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
